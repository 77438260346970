import { useTranslation } from "react-i18next";

export const GetMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("mainMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    Dashboard: { text: t("Dashboard"), icon: "MainMenu/dashboard-icon.svg" },
    Sanghatana: { text: t("Sanghatana"), icon: "MainMenu/home.svg" },
    User: { text: t("User"), icon: "MainMenu/user-icon.svg" },
    Report: { text: t("Report"), icon: "MainMenu/report-icon.svg" },
    Setting: { text: t("Setting"), icon: "MainMenu/setting-icon.svg" },
    Service: { text: t("Service"), icon: "MainMenu/service-icon.svg" },
    Notice: { text: t("Notice"), icon: "MainMenu/notice-icon.svg" },
    News: { text: t("News"), icon: "MainMenu/news-icon.svg" },
    Event: { text: t("Event"), icon: "MainMenu/event-icon.svg" },
    Inbox: { text: t("Inbox"), icon: "MainMenu/inbox-icon.svg" },
    Gallary: { text: t("Gallary"), icon: "MainMenu/gallary-icon.svg" },
    Idea: { text: t("Idea"), icon: "MainMenu/idea-icon.svg" },
    Financial: { text: t("Financial"), icon: "MainMenu/bachat-report.svg" },
    Logout: { text: t("Logout"), icon: "MainMenu/logout-icon.svg" },
  };
  const translation = translations[itemName] || { text: itemName, icon: "" };
  return translation;
};

export const GetSubMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("subMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    Register: { text: t("Register"), icon: "MainMenu/register-icon-icon.svg" },
    "All User": { text: t("All User"), icon: "MainMenu/all-users-icon.svg" },
    "Add Sanghatana": {
      text: t("Add Sanghatana"),
      icon: "MainMenu/add-sanghatana-icon.svg",
    },
    "Add Event": { text: t("Add Event"), icon: "MainMenu/add-event-icon.svg" },
    "Event Report": {
      text: t("Event Report"),
      icon: "MainMenu/event-report-icon.svg",
    },
    "Add Notice": {
      text: t("Add Notice"),
      icon: "MainMenu/add-notice-icon.svg",
    },
    "Add News": { text: t("Add News"), icon: "MainMenu/add-news-icon.svg" },
    "Notice Report": {
      text: t("Notice Report"),
      icon: "MainMenu/notice-report-icon.svg",
    },
    "Send Message": {
      text: t("Send Message"),
      icon: "MainMenu/send-message-icon.svg",
    },
    Report: { text: t("Report"), icon: "MainMenu/home.svg" },
    "News Report": {
      text: t("News Report"),
      icon: "MainMenu/news-report-icon.svg",
    },
    "User Registration": {
      text: t("User Registration"),
      icon: "MainMenu/user-registration-icon.svg",
    },
    "Sanghatana Report": {
      text: t("Sanghatana Report"),
      icon: "MainMenu/sanghatana-report-icon.svg",
    },
    "Add Idea": { text: t("Add Idea"), icon: "MainMenu/add-idea-icon.svg" },
    "Idea Report": {
      text: t("Idea Report"),
      icon: "MainMenu/idea-report-icon.svg",
    },
    "Key Setting": {
      text: t("Key Setting"),
      icon: "MainMenu/key-setting-icon.svg",
    },
    "Message Setting": {
      text: t("Message Setting"),
      icon: "MainMenu/message-setting-icon.svg",
    },
    "SMS Setting": {
      text: t("SMS Setting"),
      icon: "MainMenu/sms-setting-icon.svg",
    },
    "Gallary Upload": {
      text: t("Gallary Upload"),
      icon: "MainMenu/gallary-upload-icon.svg",
    },
    "Gallary Report": {
      text: t("Gallary Report"),
      icon: "MainMenu/gallary-report-icon.svg",
    },
    "User Report": {
      text: t("User Report"),
      icon: "MainMenu/user-report-icon.svg",
    },
    "Idea Status": {
      text: t("Idea Status"),
      icon: "MainMenu/idea-status-icon.svg",
    },
    "Financial Help": {
      text: t("Financial Help"),
      icon: "MainMenu/financial-help-icon.svg",
    },
    "Financial Report": {
      text: t("Financial Report"),
      icon: "MainMenu/financial-report-icon.svg",
    },
    "Financial Help Report": {
      text: t("Financial Help Report"),
      icon: "MainMenu/financial-help-report-icon.svg",
    },
    "Incoming Message": {
      text: t("Incoming Message"),
      icon: "MainMenu/incoming-message-icon.svg",
    },
  };
  const translation = translations[itemName] || itemName;
  return translation;
};
