import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGOUT } from "src/component/helper/constants";
import { useNavigate } from "react-router";
import { Logout } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import LanguagePopover from "../util/language-popover";
import AccountPopover from "../nav/header-bar/account-popover";

export default function AccountMenu() {
  const userInfo = useAppSelector((state) => state.user.userInfo);

  const [UserAvaterImagePath] = useState(userInfo.ProfileImagePath);

  const [currentUserTypeID] = useState(Number(userInfo.UserTypeID) ?? 0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handlePassClick = () => {
    navigate("/ChangePassword");
  };
  const handleProfileClick = () => {
    navigate("/Profile");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  const { t } = useTranslation("accountMenu");
  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "auto",
              }}
            >
              <LanguagePopover />
              <AccountPopover />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: "10px", textAlign: "center", justifyContent: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={
                UserAvaterImagePath ? UserAvaterImagePath : "/employee_icon.png"
              }
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#32475C",
              mt: "10px",
              fontSize: "20px",
              fontWeight: "550",
              fontFamily: "Poppins",
            }}
          >
            {userInfo.userName}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#32475C", fontFamily: "Poppins", fontWeight: "550" }}
          >
            {userInfo.UserType}
          </Typography>
        </Box>
        <Divider sx={{ mb: "8px" }} />
        <MenuItem
          onClick={handleProfileClick}
          sx={{ fontFamily: "Poppins", fontWeight: "300", fontSize: "16px" }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>{" "}
          {t("Profile")}
        </MenuItem>
        <MenuItem
          onClick={handlePassClick}
          sx={{ fontFamily: "Poppins", fontWeight: "300", fontSize: "16px" }}
        >
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          {t("Change Password")}
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => handlelogout()}
          sx={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "16px" }}
        >
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: "#ff0000" }} />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </>
  );
}
