import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux";

const InboxReportScreen = lazy(
  () => import("src/pages/innerPages/report/inboxReport/inboxReportScreen")
);
const AllUserReportScreen = lazy(
  () => import("src/pages/innerPages/report/user_Report/userReportScreen")
);
const SendMessageScreen = lazy(
  () => import("src/pages/innerPages/send_Message/sendMessageScreen")
);
const NoticeReportScreen = lazy(
  () => import("src/pages/innerPages/report/noticeReport/noticeReportScreen")
);
const AddNoticeScreen = lazy(
  () => import("src/pages/innerPages/add_notice/addNoticeScreen")
);
const EventReportScreen = lazy(
  () => import("src/pages/innerPages/report/eventReport/eventReportScreen")
);
const SanghatanaDashboardScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/dashboard/sanghatana_dashboard/sanghatanaDashboardScreen"
    )
);

const AddEventScreen = lazy(
  () => import("src/pages/innerPages/add_event/addEventScreen")
);
const AddSanghatanaScreen = lazy(
  () => import("src/pages/innerPages/add_sanghatana/addSanghatanaScreen")
);
const KeySettingScreen = lazy(
  () => import("src/pages/innerPages/key_setting/keySettingScreen")
);
const MessageSettingAddScreen = lazy(
  () => import("src/pages/innerPages/message_setting/messageSettingScreen")
);
const SMSSettingAddScreen = lazy(
  () => import("src/pages/innerPages/sms/smsSettingAddScreen")
);

const AddNewsScreen = lazy(
  () => import("src/pages/innerPages/add_news/addNewsScreen")
);
const NewsReportScreen = lazy(
  () => import("src/pages/innerPages/report/addNews/newsReportScreen")
);
const AddIdeaScreen = lazy(
  () => import("src/pages/innerPages/add_idea/addIdeaScreen")
);
const IdeaReportScreen = lazy(
  () => import("src/pages/innerPages/report/IdeaReport/IdeaReportScreen")
);

const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);

const UsersProfileScreen = lazy(
  () => import("src/pages/innerPages/profile/index")
);

const GallaryUploadScreen = lazy(
  () => import("src/pages/innerPages/gallary_upload/gallaryUploadScreen")
);

const GallaryReportScreen = lazy(
  () => import("src/pages/innerPages/report/gallaryReport/gallaryReportScreen")
);

const AddFinancialScreen = lazy(
  () => import("src/pages/innerPages/add_financial/addFinancialScreen")
);

const FinancialReportScreen = lazy(
  () =>
    import("src/pages/innerPages/report/financialReport/FinancialReportScreen")
);

const FinancialHelpScreen = lazy(
  () => import("src/pages/innerPages/financial_help/financialHelpScreen")
);

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/Key_Setting" element={<KeySettingScreen />} />
          <Route
            path="/Message_Setting"
            element={<MessageSettingAddScreen />}
          />
          <Route path="/SMS_Setting" element={<SMSSettingAddScreen />} />
          <Route path="/Add_Sanghatana" element={<AddSanghatanaScreen />} />
          <Route path="/Add_Event" element={<AddEventScreen />} />
          <Route path="/ChangePassword" element={<ChangePasswordAddScreen />} />
          <Route
            path="/Profile"
            element={<UsersProfileScreen IsUserProfileUploaded={false} />}
          />
          <Route
            path="/SanghatanaDashboard"
            element={<SanghatanaDashboardScreen />}
          />
          <Route path="/Add_News" element={<AddNewsScreen />} />
          <Route path="/Event_Report" element={<EventReportScreen />} />
          <Route path="/News_Report" element={<NewsReportScreen />} />
          <Route path="/Add_Notice" element={<AddNoticeScreen />} />
          <Route path="/Notice_Report" element={<NoticeReportScreen />} />
          <Route path="/Send_Message" element={<SendMessageScreen />} />
          <Route path="/Incoming_Message" element={<InboxReportScreen />} />
          <Route path="/Gallary_Upload" element={<GallaryUploadScreen />} />
          <Route path="/Gallary_Report" element={<GallaryReportScreen />} />
          <Route path="/User_Report" element={<AllUserReportScreen />} />
          <Route path="/Add_Idea" element={<AddIdeaScreen />} />
          <Route path="/Idea_Report" element={<IdeaReportScreen />} />
          <Route path="/Financial_Help" element={<AddFinancialScreen />} />
          <Route path="/Financial_Report" element={<FinancialReportScreen />} />
          <Route
            path="/Financial_Help_Report"
            element={<FinancialHelpScreen />}
          />
          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
