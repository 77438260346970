import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { UserInfo, Account } from "src/component/nav/mock/account";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    onClick: "/Profile",
    icon: "eva:person-fill",
  },
  {
    label: "Change Password",
    onClick: "/ChangePassword",
    icon: "eva:settings-2-fill",
  },
];

// ----------------------------------------------------------------------

const AccountPopover: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem: string) => {
    navigate(menuItem);
    handleClose();
  };

  const handlelogout = () => {
    LOGOUT();
  };
  const account: Account = {
    displayName: userInfo.userName,
    photoURL: userInfo.ProfileImagePath,
    role: userInfo.UserType,
    email: "",
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 50,
          height: 50,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(anchorEl && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={account.photoURL}
          alt={account.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {account.photoURL}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {account.role}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleMenuItemClick(option.onClick)}
          >
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handlelogout}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};

export default AccountPopover;
