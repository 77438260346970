import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Routes from "./component/routes/routes";

import { Provider } from "react-redux";
import { createStore } from "redux";
import store from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
let persistor = persistStore(store);
 
const App: React.FC = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <ToastContainer />
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
