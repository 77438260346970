import { Box, Grid, Link, Typography } from "@mui/material";

export default function Footer() {
  return (
    <>
      <Box
        sx={{
          // mt: "40px",
          // mx: "24px",
          bgcolor: "#0C0E10",
          // p: "60px 40px",
          // borderRadius: "24px 24px 0px 0px",
          // mb: "-8px",
          m: "0px -8px -8px -8px",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              textAlign: "left",
              mt: "40px",
              "@media (max-width: 599px)": {
                textAlign: "center",
              },
            }}
          >
            <Link href="/">
              <img src="logo.png" alt="Footer Logo" width="200px" />
            </Link>

            <Grid sx={{ mt: "14px" }}>
              <Typography
                sx={{ color: "#9A9B9B", fontSize: "16px", fontWeight: "400" }}
              >
                <Link
                  href="https://goo.gl/maps/KDWgqNeaeXwGKRV48"
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  At/Post: Masur,Tal:Karad,Dist:Satara
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            sx={{
              textAlign: "left",
              mt: "40px",
              "@media (max-width: 599px)": {
                textAlign: "center",
              },
            }}
          >
            <Grid>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
              >
                Usefull Links
              </Typography>

              <Grid sx={{ color: "#000", mt: "14px" }}>
                <Link
                  href="/"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Typography sx={{ fontWeight: "400", fontSize: "16px" }}>
                    Home
                  </Typography>
                </Link>

                <Link
                  href="/"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Typography
                    sx={{ mt: "6px", fontWeight: "400", fontSize: "16px" }}
                  >
                    About
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            sx={{
              textAlign: "left",
              mt: "40px",
              "@media (max-width: 599px)": {
                textAlign: "center",
              },
            }}
          >
            <Grid>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
              >
                Policies
              </Typography>

              <Grid
                sx={{
                  color: "white",
                  mt: "14px",
                  fontWeight: "300",
                  fontSize: "20px",
                }}
              >
                <Link
                  href="/terms_conditions"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Typography
                    sx={{ mt: "6px", fontWeight: "400", fontSize: "16px" }}
                  >
                    Term & Conditions
                  </Typography>
                </Link>

                <Link
                  href="/privacy_policy"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Typography
                    sx={{ mt: "6px", fontWeight: "400", fontSize: "16px" }}
                  >
                    Policy Policy
                  </Typography>
                </Link>
                <Link
                  href="/cancellation_refund_policy"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Typography
                    sx={{ mt: "6px", fontWeight: "400", fontSize: "16px" }}
                  >
                    Cancellation & Refund Policy
                  </Typography>
                </Link>
                <Link
                  href="/contact_us"
                  sx={{
                    textDecoration: "none",
                    color: "#9A9B9B",
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <Typography
                    sx={{ mt: "6px", fontWeight: "400", fontSize: "16px" }}
                  >
                    Contact Us
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            sx={{
              textAlign: "left",
              mt: "40px",
              "@media (max-width: 599px)": {
                textAlign: "center",
              },
            }}
          >
            {/* <Grid>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
              >
                Follow On
              </Typography>

              <Grid container sx={{ mt: "14px" }}>
                <Grid item md={12} lg={12} xl={12}>
                  <Button
                    href="https://www.facebook.com/GramUrja/"
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<FacebookIcon />}
                    sx={{
                      color: "#9A9B9B",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "#fff",
                        bgcolor: "transparent",
                      },
                    }}
                  >
                    Facebook
                  </Button>
                </Grid>

                <Grid item md={12} lg={12} xl={12}>
                  <Button
                    href="https://www.instagram.com/gramurja/"
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<InstagramIcon />}
                    sx={{
                      color: "#9A9B9B",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "#fff",
                        bgcolor: "transparent",
                      },
                    }}
                  >
                    Instagram
                  </Button>
                </Grid>

                <Grid item md={12} lg={12} xl={12}>
                  <Button
                    href="https://www.linkedin.com/company/gramurja-foundation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<LinkedInIcon />}
                    sx={{
                      color: "#9A9B9B",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "#fff",
                        bgcolor: "transparent",
                      },
                    }}
                  >
                    LinkedIn
                  </Button>
                </Grid>

                <Button
                  href="https://www.youtube.com/channel/UCUfD747Ch0ND1p6Cngy7HXw"
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<YouTubeIcon />}
                  sx={{
                    color: "#9A9B9B",
                    textTransform: "capitalize",
                    "&:hover": {
                      color: "#fff",
                      bgcolor: "transparent",
                    },
                  }}
                >
                  Youtube
                </Button>
              </Grid>
            </Grid> */}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: "15px",
              mt: "40px",
              textAlign: "center",
              fontFamily: "sans-serif",
              mb: "24px",
            }}
          >
            Copyright 2024 by Mazi Sanghatana
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
