export const resources = {
  en: {
    dashboardScreen: {
      "Total members": "Total members",
      "Total Events": "Total Events",
      "Help Counter": "Help Counter ",
    },
    addGallaryScreen: {
      "Gallary Upload": "Gallary Upload",
      Title: "Title",
      "Page Type": "Page Type",
      "Select Image": "Select Image",
      "Click here to upload Image": "Click here to upload Image",
      Cancle: "Cancle",
      Add: "Add",
      "Gallary Report": "Gallary Report",
      FromDate: "From Date",
      ToDate: "To Date",
      Submit: "Submit",
      Image: "Image",
      PageType: "Page Type",
      Like: "Like",
      Count: "Count",
      Delete: "Delete",
      "Title is required": "Title is required",
      "Page Type is required": "Page Type is required",
    },
    mainMenuList: {
      Dashboard: "Dashboard",
      Sanghatana: "Sanghatana",
      User: "User",
      Report: "Report",
      Setting: "Setting",
      Notice: "Notice",
      News: "News",
      Event: "Event",
      Inbox: "Inbox",
      Idea: "Idea",
      Logout: "Logout",
      Gallary: "Gallary",
      Financial: "Financial",
    },
    forgotPassword: {
      "Forgot Password?": "Forgot Password?",
      "Do you want to login?": "Do you want to login?",
      "Return to Login": "Return to Login",
      "No worries, we'll send you rest instructions.":
        "No worries, we'll send you rest instructions.",
      MobileNumber: "Mobile Number",
      PROCEED: "PROCEED",
      "Mobile Number is required": "Mobile Number is required",
    },
    subMenuList: {
      "Notice Report": "Notice Report",
      "Add Event": "Add Event",
      "Event Report": "Event Report",
      "Add Sanghatana": "Add Sanghatana",
      "Sanghatana Report": "Sanghatana Report",
      "Add Notice": "Add Notice",
      "Add News": "Add News",
      "User Registration": "User Registration",
      "Send Message": "Send Message",
      "Incoming Message": "Incoming Message",
      "News Report": "News Report",
      "Add Idea": "Add Idea",
      "Idea Report": "Idea Report",
      "Message Setting": "Message Setting",
      "SMS Setting": "SMS Setting",
      "Key Setting": "Key Setting",
      "User Report": "User Report",
      "Idea Status": "Idea Status",
      "Gallary Upload": "Gallary Upload",
      "Gallary Report": "Gallary Report",
      "Financial Help": "Financial Help",
      "Financial Report": "Financial Report",
      "Financial Help Report": "Financial Help Report",
    },
    accountMenu: {
      Profile: "Profile",
      "Change Password": "Change Password",
      Logout: "Logout",
      GroupName: "Group Name",
      Date: "Date",
    },
    loginScreen: {
      "Maharastra Rajya Sanghatana": "Maharastra Rajya Sanghatana",
      "Please sign-in to your account": "Please sign-in to your account",
      MobileNumber: "Mobile Number",
      Password: "Password",
      "Forgot password": "Forgot password?",
      "Sign In": "Sign In",
      "Forgot password?": "Forgot password?",
      "Don't have an account?": "Don't have an account?",
      "Sign Up": "Sign Up",
      "Phone number is required": "Phone number is required",
      "Password is required": "Password is required",
      "Back to home": "Back to home",
    },
    addSanghatanaScreen: {
      "Add Sanghatana": "Add Sanghatana",
      "Sanghatana Name": "Sanghatana Name",
      "Mobile Number": "Mobile Number",
      "Email ID": "Email ID",
      "Designation Name": "Designation Name",
      "Phone Number": "Phone Number",
      "Registration Date": "Registration Date",
      "Select role": "Select role",
      "Select designation": "Select designation",
      "Are you sure to delete sanghatna?": "Are you sure to delete sanghatna?",
      "Select state": "Select state",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      UserType: "UserType",
      Address: "Address",
      Edit: "Edit",
      Delete: "Delete",
    },
    customMultipleImagePicker: {
      "Click here to upload image": "Click here to upload image",
    },
    addEventScreen: {
      "Event Report": "Event Report",
      FromDate: "FromDate",
      ToDate: "To Date",
      "Add Event": "Add Event",
      "Event Name": "Event Name",
      "Event Type": "Event Type",
      "Event Date": "Event Date",
      "Event Location": "Event Location",
      Description: "Description",
      "Select type": "Select type",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      Edit: "Edit",
      Delete: "Delete",
      "Organized by": "Organized by",
      Image: "Image",
      View: "Image View",
      "Delete Confirmation": "Delete Confirmation",
      "Are you sure to delete event details":
        "Are you sure to delete event details",
    },
    offlineEventActionPopUp: {
      View: "View",
      ImageName: "Image Name",
    },
    addNewsScreen: {
      "Add News": "Add News",
      "User Type": "User Type",
      "News Type": "News Type",
      Message: "Message",
      Description: "Description",
      "Hyper Link": "Hyper Link",
      "Is WhatsApp": "Is WhatsApp",
      "Is Mail": "Is Mail",
      "Is SMS": "Is SMS",
      "Select role": "Select role",
      "Select type": "Select type",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      FromDate: "FromDate",
      ToDate: "To Date",
      Submit: "Submit",
      "Delete All": "Delete All",
      Image: "Image",
      NewsType: "NewsType",
      UserName: "UserName",
      Delete: "Delete",
      HyperLink: "HyperLink",
      "News Report": "News Report",
      "Are you sure to delete news?": "Are you sure to delete news?",
      "Are you sure to delete all news?": "Are you sure to delete all news?",
    },
    addNoticeScreen: {
      "Notice is required": "Notice is required",
      "Select role": "Select role",
      "Add Notice": "Add Notice",
      Notice: "Notice",
      Submit: "Submit",
      "Delete All": "Delete All",
      "User Type": "User Type",
      "Is WhatsApp": "Is WhatsApp",
      "Is Mail": "Is Mail",
      "Is SMS": "Is SMS",
      Cancel: "Cancel",
      Add: "Add",
      FromDate: "From Date",
      ToDate: "To Date",
      "Notice Report": "Notice Report",
      Delete: "Delete",
      "Are you sure to delete notice details":
        "Are you sure to delete notice details",
      "Delete Confirmation": "Delete Confirmation",
      UserName: "UserName",
    },
    sendMessageScreen: {
      Submit: "Submit",
      "Delete All": "Delete All",
      FromDate: "From Date",
      ToDate: "To Date",
      "Send Message": "Send Message",
      Message: "Message",
      "Member Name": "Member Name",
      "Is WhatsApp": "Is WhatsApp",
      "Is Mail": "Is Mail",
      "Is SMS": "Is SMS",
      Cancel: "Cancel",
      Add: "Add",
      "Message Report": "Message Report",
      Date: "Date",
      Delete: "Delete",
      "To User": "To User",
      "From Member": "From Member",
      "To Member": "To Member",
    },
    SignUpScreen: {
      "Maharastra Rajya Sanghatana": "Maharastra Rajya Sanghatana",
      "Please create your account": "Please create your account",
      "User Name": "User Name",
      "Mobile Number": " Mobile Number",
      "Email ID": "Email ID",
      "Date Of Birth": "Date Of Birth",
      Address: "Address",
      Password: "Password",
      "State Name": "State Name",
      "District Name": "District Name",
      "Taluka Name": "Taluka Name",
      "Select state": "Select state",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      "Already have an account?": "Already have an account?",
      "Sign in": "Sign in",
      Reset: "Reset",
      Add: "Add",
      "User Name is required": "User Name is required",
      "Address is required": "Address is required",
      "Mobile Number is required": "Mobile Number is required",
      "Select village": "Select village",
      "Village Name": "Village Name",
    },
    addUserReport: {
      "Select District": "Select District",
      "Select Taluka": "Select Taluka",
      "District Name": "District Name",
      "Taluka Name": "Taluka Name",
      UserName: "UserName",
      View: "View",
      UserType: "User Type",
      Submit: "Submit",
      MobileNumber: "Mobile Number",
      EmailID: "Email ID",
      Address: "Address",
      Taluka: "Taluka",
      District: "District",
      State: "State",
      Village: "Village",
      FullName: "FullName",
      "User Report": "User Report",
    },
    addIdeaScreen: {
      "Add Idea": "Add Idea",
      Idea: "Idea",
      FromDate: "FromDate",
      ToDate: "ToDate",
      "Start Date": "Start Date",
      "End Date": "End Date",
      Cancel: "Cancel",
      Add: "Add",
      "Idea Report": "Idea Report",
      Count: "Count",
      Delete: "Delete",
      Like: "Like",
      Submit: "Submit",
    },
    addFinancialScreen: {
      "Add Financial Help": "Add Financial Help",
      "Member Name": "Member Name",
      "Bank Account Number": "Bank Account Number",
      "IFSC Code": "IFSC Code",
      Description: "Description",
      "Select User": "Select User",
      "Select Image": "Select Image",
      "Click here to upload Image": "Click here to upload Image",
      Add: "Add",
      Cancel: "Cancel",
      "Financial Help Report": "Financial Help Report",
      FromDate: "From Date",
      ToDate: "To Date",
      Submit: "Submit",
      Image: "Image",
      "Financial Report": "Financial Report",
      "Account Number": "Account Number",
      Delete: "Delete",
      Amount: "Amount",
      Date: "Date",
      "Payment Receipt": "Payment Receipt",
      "From Member": "From Member",
      "To Member": "To Member",
      "Amount number is required": "Amount number is required",
    },
  },
  mr: {
    dashboardScreen: {
      "Total members": "एकूण सदस्य",
      "Total Events": "एकूण कार्यक्रम ",
      "Help Counter": "मदत काउंटर ",
    },
    addGallaryScreen: {
      "Gallary Upload": "गॅलरी अपलोड",
      Title: "शीर्षक",
      "Page Type": "पृष्ठ प्रकार",
      "Select Image": "प्रतिमा निवडा",
      "Click here to upload Image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
      Cancle: "Cancle",
      Add: "जोडा",
      "Gallary Report": "गॅलरी अहवाल",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Submit: "सबमिट करा",
      Image: "प्रतिमा",
      PageType: "पृष्ठ प्रकार",
      Like: "आवडले",
      Count: "काउन्ट ",
      Delete: "हटवा",
      "Title is required": "शीर्षक आवश्यक आहे",
      "Page Type is required": "पृष्ठ प्रकार आवश्यक आहे",
    },
    addUserReport: {
      "User Report": "वापरकर्ता अहवाल",
      "Select District": "जिल्हा निवडा",
      "Select Taluka": "तालुका निवडा",
      "District Name": "जिल्ह्याचे नाव",
      "Taluka Name": "तालुक्याचे नाव",
      "User Name": "वापरकर्त्याचे नाव",
      View: "पहा",
      "User Type": "वापरकर्त्याचा प्रकार",
      Submit: "सबमिट करा",
      "Mobile Number": "मोबाईल नंबर",
      "Email ID": "ईमेल आयडी",
      Address: "पत्ता",
      Taluka: "तालुका",
      District: "जिल्हा",
      State: "राज्य",
      Village: "गाव",
      FullName: "वापरकर्त्याचा प्रकार",
    },
    sendMessageScreen: {
      "To User": "सदस्यांना",
      "Message Report": "संदेश अहवाल",
      "Is WhatsApp": "व्हॉट्सॲप",
      "Is Mail": "ईमेल",
      "Is SMS": "एसएमएस",
      "Send Message": "संदेश पाठवा",
      Message: "संदेश",
      "Member Name": "सदस्याचे नाव",
      Cancel: "रद्द करा",
      Add: "जोडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      "Delete All": "सर्व हटवा",
      Submit: "सबमिट करा",
      Date: "तारीख",
      Delete: "हटवा",
      "From Member": "ह्या वापरकर्त्याकडून",
      "To Member": "ह्या वापरकर्त्याला",
    },
    forgotPassword: {
      "Forgot Password?": "पासवर्ड विसरलात?",
      "Return to Login": "लॉगिन वर परत या",
      "Do you want to login?": "तुम्हाला लॉगिन करायचे आहे का?",
      "No worries, we'll send you rest instructions.":
        " काळजी करू नका, आम्ही तुम्हाला उर्वरित सूचना पाठवू.",
      MobileNumber: "मोबाईल नंबर",
      PROCEED: "पुढे जा",
      "Mobile Number is required": "मोबाईल नंबर आवश्यक आहे",
    },
    addNoticeScreen: {
      "Are you sure to delete notice details":
        "तुम्हाला सूचना तपशील हटवण्याची खात्री आहे",
      "Delete Confirmation": "पुष्टीकरण हटवा",
      "Select role": "भूमिका निवडा",
      "Add Notice": "सूचना जोडा",
      Notice: "सूचना ",
      "User Type": "वापरकर्ता प्रकार",
      "Is WhatsApp": "व्हॉट्सॲप",
      "Is Mail": "ईमेल",
      "Is SMS": "एसएमएस",
      Cancel: "रद्द करा",
      Add: "जोडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      "Notice Report": "सूचना अहवाल",
      "Notice is required": "सूचना आवश्यक आहे",
      Delete: "हटवा",
      "Delete All": "सर्व हटवा",
      Submit: "सबमिट करा",
      UserName: "वापरकर्त्याचे नाव ",
    },
    offlineEventActionPopUp: {
      View: "पहा",
      ImageName: "प्रतिमा नाव",
    },
    addEventScreen: {
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      "Event Report": "कार्यक्रम अहवाल",
      "Are you sure to delete event details":
        "तुम्हाला इव्हेंट तपशील हटवण्याची खात्री आहे",
      "Delete Confirmation": "पुष्टीकरण हटवा",
      View: "प्रतिमा पहा",
      "Add Event": "इव्हेंट जोडा",
      "Event Name": "इव्हेंटचे नाव",
      "Event Type": "इव्हेंट प्रकार",
      "Event Date": "कार्यक्रमाची तारीख",
      "Event Location": "कार्यक्रमाचे स्थान",
      Description: "वर्णन",
      "Select type": "प्रकार निवडा",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      Edit: "संपादित करा",
      Delete: "हटवा",
      "Organized by": "द्वारा आयोजित",
      Image: "प्रतिमा",
      "Delete All": "सर्व हटवा",
      Submit: "सबमिट करा",
    },
    customMultipleImagePicker: {
      "Click here to upload image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
    },
    addSanghatanaScreen: {
      "Add Sanghatana": "संघटना",
      "Sanghatana Name": "संघटनाचे नाव",
      "Mobile Number": "मोबाइल नंबर",
      "Email ID": "ई-मेल आयडी",
      "Designation Name": "पदनाम",
      "Phone Number": "फोन नंबर",
      "Registration Date": "नोंदणी दिनांक",
      "Select role": "भूमिका निवडा",
      "Select designation": "पदनाम निवडा",
      "Are you sure to delete sanghatna?":
        "तुम्हाला संघटना हटवायची खात्री आहे का?",
      "Select state": "राज्य निवडा",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      Edit: "संपादित करा",
      UserType: "वापरकर्ता प्रकार",
      Address: "पत्ता",
      Delete: "हटवा",
    },
    subMenuList: {
      "Notice Report": "सूचना अहवाल",
      "Add Event": "इव्हेंट जोडा",
      "Event Report": "कार्यक्रम अहवाल",
      "Add Sanghatana": "संघटना जोडा",
      "Sanghatana Report": "संघटनेचा अहवाल",
      "Add Notice": "सूचना जोडा",
      "Add News": "बातम्या जोडा",
      "User Registration": "वापरकर्ता नोंदणी",
      "Send Message": "संदेश पाठवा",
      "Incoming Message": "येणारा संदेश",
      "News Report": "बातम्या अहवाल",
      "Add Idea": "आयडिया जोडा",
      "Idea Report": "आयडिया अहवाल",
      "Key Setting": "की सेटिंग",
      "Message Setting": "संदेश सेटिंग",
      "SMS Setting": "एसएमएस सेटिंग",
      "User Report": "वापरकर्ता अहवाल",
      "Idea Status": "आयडिया स्थिती",
      "Gallary Upload": "गॅलरी अपलोड",
      "Gallary Report": "गॅलरी अहवाल",
      "Financial Help": "आर्थिक मदत",
      "Financial Report": "आर्थिक अहवाल",
      "Financial Help Report": "आर्थिक मदत अहवाल",
    },
    mainMenuList: {
      Dashboard: "मुखपृष्ठ",
      Sanghatana: "संघटना",
      User: "वापरकर्ता",
      Report: "अहवाल",
      Setting: "सेटिंग",
      Notice: "सूचना",
      News: "बातम्या",
      Event: "कार्यक्रम",
      Inbox: "इनबॉक्स",
      Idea: "आयडिया",
      Logout: "बाहेर पडणे",
      Gallary: "गॅलरी",
      Financial: "आर्थिक व्यवहार ",
    },
    loginScreen: {
      "Maharastra Rajya Sanghatana": "महाराष्ट्र राज्य संघटना!",
      "Please sign-in to your account": "साइन इन करा",
      MobileNumber: "मोबाईल नंबर",
      Password: "पासवर्ड",
      "Forgot password": "पासवर्ड विसरलात?",
      "Sign In": "साइन इन",
      "Forgot password?": "पासवर्ड विसरलात?",
      "Don't have an account?": "खाते नाही?",
      "Sign Up": "साइन अप",
      "Phone number is required": "फोन नंबर आवश्यक आहे",
      "Password is required": "पासवर्ड आवश्यक आहे",
      "Back to home": "मुख्य पृष्ठ",
    },
    accountMenu: {
      Profile: "प्रोफाइल",
      Logout: "बाहेर पडणे",
      "Change Password": "पासवर्ड बदला",
      GroupName: "गटाचे नाव",
      Date: "तारीख",
    },
    addNewsScreen: {
      "Add News": "बातम्या जोडा",
      "User Type": "वापरकर्त्याचे प्रकार ",
      "News Type": "बातम्या प्रकार",
      "Select role": "भूमिका निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Message: "संदेश",
      Description: "वर्णन",
      "Hyper Link": "हायपरलिंक",
      "Is WhatsApp": "व्हॉट्सॲप",
      "Is Mail": "ईमेल",
      "Is SMS": "एसएमएस",
      "Select type": "प्रकार निवडा",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Delete All": "सर्व हटवा",
      Image: "प्रतिमा",
      NewsType: "बातम्यांचा प्रकार",
      UserName: "वापरकर्त्याचे नाव ",
      Delete: "हटवा",
      HyperLink: "हायपरलिंक",
      Submit: "सबमिट करा",
      "News Report": "बातम्या अहवाल",
      "Are you sure to delete news?": "तुम्ही बातम्या हटवणार आहात का?",
      "Are you sure to delete all news?": "तुम्ही सर्व बातम्या हटवणार आहात का?",
    },
    SignUpScreen: {
      "Maharastra Rajya Sanghatana": "महाराष्ट्र राज्य संघटना !",
      "Please create your account": "कृपया तुमचे खाते तयार करा",
      "User Name": "वापरकर्त्याचे नाव",
      "Mobile Number": "मोबाईल नंबर",
      "Email ID": "ईमेल आयडी",
      "Date Of Birth": "जन्मतारीख",
      Address: "पत्ता",
      Password: "पासवर्ड",
      "State Name": "राज्याचे नाव",
      "District Name": "जिल्ह्याचे नाव",
      "Taluka Name": "तालुक्याचे नाव",
      "Select state": "राज्य निवडा",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      "Already have an account?": "या पूर्वी खाते होते का ?",
      "Sign in": "साइन इन करा",
      Add: "माहिती भरा ",
      Reset: "रीसेट करा",
      "User Name is required": "वापरकर्त्याचे नाव आवश्यक आहे.",
      "Address is required": "पत्ता आवश्यक आहे",
      "Mobile Number is required": "मोबाइल नंबर आवश्यक आहे.",
      "Select village": "गाव निवडा",
      "Village Name": "गावाचे नाव",
    },
    addIdeaScreen: {
      "Add Idea": "आयडिया जोडा",
      Idea: "आयडिया",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      "Start Date": "प्रारंभ तारीख",
      "End Date": "समाप्ती तारीख",
      Cancel: "रद्द करा",
      Add: "जोडा",
      "Idea Report": "आयडिया अहवाल",
      Like: "आवडले",
      Count: "काउन्ट ",
      Delete: "हटवा",
      Submit: "प्रस्तुत करणे",
    },
    addFinancialScreen: {
      "Add Financial Help": "आर्थिक मदत जोडा ",
      "Member Name": "सदस्याचे नाव",
      "Bank Account Number": "बँक खाते क्रमांक",
      "IFSC Code": "IFSC कोड",
      Description: "वर्णन",
      "Select User": "वापरकर्ता निवडा",
      "Select Image": "प्रतिमा निवडा",
      "Click here to upload Image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
      Add: "जोडा",
      Cancel: "रद्द करा",
      "Financial Report": "आर्थिक अहवाल",
      "Financial Help Report": "आर्थिक मदत अहवाल",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Submit: "सबमिट करा",
      Image: "प्रतिमा",
      "Account Number": "खाते क्रमांक",
      Delete: "हटवा",
      Amount: "रक्कम",
      Date: "तारीख",
      "Payment Receipt": "पेमेंट पावती",
      "From Member": "ह्या वापरकर्त्याकडून",
      "To Member": "ह्या वापरकर्त्याला",
    },
  },
};
