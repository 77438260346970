import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "src/component/navigation/publicNavbar";
const LoginScreen = lazy(
  () => import("src/pages/outerPages/login/loginScreen")
);
const SignUpScreen = lazy(
  () => import("src/pages/outerPages/sign_up/signUpScreen")
);

const ForgetPasswordScreen = lazy(
  () => import("src/pages/outerPages/forgotPassword/forgotPasswordScreen")
);
const AuthRoute: React.FC = () => {
  return (
    <AuthLayout>
      <Suspense>
        <Routes>
        <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignUpScreen />} />
          <Route path="/forgot_password" element={<ForgetPasswordScreen />} />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
