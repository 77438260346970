import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import i18n from "src/component/util/languageResource";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: "/assets/english-translate.svg",
  },
  {
    value: "Mar",
    label: "Marathi",
    icon: "/assets/marathi-translate.svg",
  },
];

// ----------------------------------------------------------------------

const LanguagePopover: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentLang, setCurrentLang] = useState(() => {
    // Retrieve the selected language from sessionStorage or fallback to the default language
    return sessionStorage.getItem("selectedLang") || i18n.language;
  });

  useEffect(() => {
    // Store the selected language in sessionStorage whenever it changes
    sessionStorage.setItem("selectedLang", currentLang);
  }, [currentLang]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 50,
          height: 50,
          marginRight: "10px",
          ...(anchorEl && {
            bgcolor: "action.selected",
          }),
        }}
      >
        <img
          src={
            LANGS.find((lang) => lang.value === currentLang)?.icon ||
            LANGS[0].icon
          }
          alt={currentLang}
        />
      </IconButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang}
            onClick={() => changeLanguage(option.value)}
            sx={{ typography: "body2", py: 1 }}
          >
            <Box
              component="img"
              alt={option.label}
              src={option.icon}
              sx={{ width: 28, mr: 2 }}
            />
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
